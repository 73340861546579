import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="May 2020" subnav="release-notes">
      <div id="May2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Another month has come and gone—just a light release as we head into
          summer.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                All you need to know about using the Creatable{' '}
                <Link href="/components/forms/select/code/#Creatable%20Select">
                  select
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.2.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.2.0"
            />

            <div className={styles.changesList}>
              <Added>
                The Creatable option to the{' '}
                <Link href="/components/forms/select/code/#Creatable%20Select">
                  select
                </Link>
                .
              </Added>
              <Fixed>
                The text alignment on the small{' '}
                <Link href="/components/note/design">note</Link>.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
